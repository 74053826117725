// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-confidentialite-js": () => import("./../../../src/pages/confidentialite.js" /* webpackChunkName: "component---src-pages-confidentialite-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contentful-categories-name-js": () => import("./../../../src/pages/{ContentfulCategories.name}.js" /* webpackChunkName: "component---src-pages-contentful-categories-name-js" */),
  "component---src-pages-contentful-health-blog-posts-category-name-contentful-health-blog-posts-slug-js": () => import("./../../../src/pages/{ContentfulHealthBlogPosts.category__name}/{ContentfulHealthBlogPosts.slug}.js" /* webpackChunkName: "component---src-pages-contentful-health-blog-posts-category-name-contentful-health-blog-posts-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politique-js": () => import("./../../../src/pages/politique.js" /* webpackChunkName: "component---src-pages-politique-js" */)
}

